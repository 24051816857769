import { Injectable } from '@angular/core';
import {Router} from '@angular/router'
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {AllApisService} from '../../all-apis.service';

@Injectable({
  providedIn: 'root'
})
export class ppAdminSyncAll {

  // category="";
  errorData: {};
  constructor(private _http: HttpClient ,private router:Router,private allApis:AllApisService) 
  { }

  syncAll = this.allApis.baseurl+'/sync/syncAll';
  syncUser = this.allApis.baseurl+'/sync/syncUser';
  syncClassesUrl = this.allApis.baseurl+'/sync/syncClasses';
  removeUsersUrl = this.allApis.baseurl+'/sync/removeUsers';
  updateOffline = this.allApis.baseurl+'/school/updateOffline';
  getSchoolApi = this.allApis.baseurl + "/school/id";

  updateSyncAll(newSchool:any, allSchool: boolean, feeAccess: boolean, admissionAccess: boolean){
    return this._http.post<any>(this.syncAll, {newSchool: newSchool, allSchool: allSchool, feeAccess: feeAccess, admissionAccess: admissionAccess}, this.allApis.httpOptions).pipe(catchError(this.handleError));
  }

  syncUsers(schoolId, allUser) {
    return this._http.post<any>(this.syncUser, {schoolId: schoolId, allUser: allUser}, this.allApis.httpOptions).pipe(catchError(this.handleError));
  }

  syncClasses(schoolId) {
    return this._http.post<any>(this.syncClassesUrl, {schoolId: schoolId}, this.allApis.httpOptions).pipe(catchError(this.handleError));
  }

  removeUsers(schoolId) {
    return this._http.post<any>(this.removeUsersUrl, {schoolId: schoolId}, this.allApis.httpOptions).pipe(catchError(this.handleError));
  }

  updateOfflineFlag(schoolId, activate) {
    return this._http.post<any>(this.updateOffline, {schoolId: schoolId, activate: activate}, this.allApis.httpOptions).pipe(catchError(this.handleError));
  }
  
  getSchool(schoolId){
    let params = new HttpParams();

    params = params.append('id', schoolId);

    // console.log(params);

    this.allApis.httpOptions.params = params;
    return this._http.get<any>(this.getSchoolApi , this.allApis.httpOptions).pipe(catchError(this.handleError));
  }

//  error handling
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {

      // A client-side or network error occurred. Handle it accordingly.

      console.error('An error occurred:', error.error.message);
    } else {

      // The backend returned an unsuccessful response code.

      // The response body may contain clues as to what went wrong,

      console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
    }

    // return an observable with a user-facing error message

    this.errorData = {
      errorTitle: 'Oops! Request for document failed',
      errorDesc: 'Something bad happened. Please try again later.'
    };
    return throwError(this.errorData);
  }

}
