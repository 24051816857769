import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
import { SignUpComponent } from "./SigninSignoutO/sign-up/sign-up.component";

import { SignInComponent } from "./SigninSignoutO/sign-in/sign-in.component";

import { SmSignInComponent } from "./SigninSignoutO/sm-sign-in/sm-sign-in.component";

import { DashboardComponent } from "./dashboardO/dashboard/dashboard.component";

import { AddFineComponent } from "./AddFine/add-fine.component";
import { CreateRouteComponent } from "./CreateRoute/create-route.component";

import { MapTransportComponent } from "./map-transport/map-transport.component";

import { AuthGuard } from "./auth_guard/auth.guard";

import { SiteV2Component } from "./site-v2/site-v2.component";

import { DashGuard } from "./auth_guard/dash-guard.guard";

import { DashContentComponent } from "./dash-content/dash-content.component";

import { ForgetPasswordComponent } from "./SigninSignoutO/forget-password/forget-password.component";

import { SmsUpdatesComponent } from "./ppadmin/sms-updates/sms-updates.component";
import { SmsUpdatesSchoolListComponent } from "./ppadmin/sms-updates-school-list/sms-updates-school-list.component";
import { PadhaiPayQueryListComponent } from "./ppadmin/padhai-pay-query-list/padhai-pay-query-list.component";
import { PPAdminSyncAllComponent } from "./ppadmin/ppadmin-sync-all/ppadmin-sync-all.component";
// import { SmsUpdatesComponent } from './sms-updates/sms-updates.component';
import { PrivacyComponent } from "./privacy/privacy.component";

import { StatusMonthWiseComponent } from "./status-month-wise/status-month-wise.component";
import { OnlineTransactionComponent } from "./ppadmin/online-transaction/online-transaction.component";

import { SchoolPaymentListComponent } from "./school-payment-list/school-payment-list.component";

import { ImportStudentSchedulingExcelComponent } from "./import-student-scheduling-excel/import-student-scheduling-excel.component";
import { SmAgentComponent } from "./ppadmin/sm-agent/sm-agent.component";
import { SideBarSearchComponent } from "./dashboardO/side-bar-search/side-bar-search.component";
import { AboutusComponent } from "./aboutus/aboutus.component";
import { ContactusComponent } from "./contactus/contactus.component";
import { TermconditionComponent } from "./termcondition/termcondition.component";
import { ViewScholarshipsComponent } from "./scholarship-module/view-scholarships/view-scholarships.component";
import { DetailsComponent } from "./scholarship-module/details/details.component";
import { RefundPolicyComponent } from "./refund-policy/refund-policy.component";
import { AppLinkComponent } from "./app-link/app-link.component";
import { BlankContentComponent } from "./blank-content/blank-content.component";
import { UploadEdocsComponent } from "./upload-edocs/upload-edocs.component";

//added later

export const routes: Routes = [
  { path: "", redirectTo: "sm-sign-in", pathMatch: "full" },
  // {path: "welcome",component:SiteV1Component,canActivate:[AuthGuard]},
  { path: "welcome", component: SiteV2Component, canActivate: [AuthGuard] },
  { path: "privacy", component: PrivacyComponent },
  { path: "fees", component: AppLinkComponent },
  { path: "refund-policy", component: RefundPolicyComponent },

  { path: "aboutus", component: AboutusComponent },
  { path: "contactus", component: ContactusComponent },
  { path: "termNcondition", component: TermconditionComponent },
  { path: "signin", component: SignInComponent },
  { path: "sm-sign-in", component: SmSignInComponent },
  { path: "signup", component: SignUpComponent },

  { path: "resetPassword", component: ForgetPasswordComponent },
  { path: "sideBarSearch", component: SideBarSearchComponent },
  {
    path: "dashboard",
    component: DashboardComponent,
    canActivate: [DashGuard],
    children: [
      //super admin
      { path: "welcomeScreen", component: DashContentComponent },
      { path: "blankContent", component: BlankContentComponent },
      // Admission

      //school
      {
        path: "school",
        loadChildren: () =>
          import("./SchoolO/SchoolO.module").then((m) => m.SchoolOModule),
      },

      //shop

      {
        path: "student",
        loadChildren: () =>
          import("./StudentO/student.module").then((m) => m.StudentModule),
      },
      {
        path: "inventory",
        loadChildren: () =>
          import("./Inventory/inventory.module").then((m) => m.InventoryModule),
      },
      {
        path: "expense",
        loadChildren: () =>
          import("./expense-management/expense.module").then(
            (m) => m.ExpenseModule
          ),
      },
      {
        path: "collect",
        loadChildren: () =>
          import("./CollectFeeO/collect-fee.module").then(
            (m) => m.CollectFeeOModule
          ),
      },
      {
        path: "admission",
        loadChildren: () =>
          import("./admission/admission.module").then((m) => m.AdmissionModule),
      },
      {
        path: "masterSettings",
        loadChildren: () =>
          import("./MasterSettingO/master-setting-o.module").then(
            (m) => m.MasterSettingOModule
          ),
      },

      {
        path: "user",
        loadChildren: () =>
          import("./UserDetailsO/user-details-o.module").then(
            (m) => m.UserDetailsOModule
          ),
      },

      {
        path: "merchant",
        loadChildren: () =>
          import("./merchant-module/merchant-module.module").then(
            (m) => m.MerchantModuleModule
          ),
      },

      //User Details

      { path: "addfine", component: AddFineComponent },
      { path: "createroute", component: CreateRouteComponent },
      { path: "createroute", component: CreateRouteComponent },

      { path: "maproute", component: MapTransportComponent },

      { path: "smsUpdates", component: SmsUpdatesComponent },
      { path: "smsUpdatesList", component: SmsUpdatesSchoolListComponent },
      { path: "onlineTransaction", component: OnlineTransactionComponent },
      { path: "smAgent", component: SmAgentComponent },

      //padhaiPayQueryList
      { path: "padhaiPayQueryList", component: PadhaiPayQueryListComponent },
      { path: "padhaiPaySyncAll", component: PPAdminSyncAllComponent },

      //MonthWiseStatus
      { path: "monthWiseStatus", component: StatusMonthWiseComponent },

      //merchant

      { path: "schoolTransactionList", component: SchoolPaymentListComponent },

      {
        path: "concession",
        loadChildren: () =>
          import("./ConcessionO/concession.module").then(
            (m) => m.ConcessionModule
          ),
      },
      //{path: 'feeReconcelationList', component: FeeReconcelationListComponent},

      //  {path: 'feepayment', component:FeePaymentComponent},
      //{path: 'importExcel', component:ImportExcelComponent},
      {
        path: "importSchedulingExcel",
        component: ImportStudentSchedulingExcelComponent,
      },

      {
        path : "uploadEdocs",
        component : UploadEdocsComponent
      },


      { path: "**", component: PageNotFoundComponent },
    ],
  },
  {
    path: "scholarship",
    loadChildren: () =>
      import("./scholarship-module/scholarship.module").then(
        (m) => m.ScholarshipModule
      ),
  },
  {
    path: "naso",
    loadChildren: () => import("./naso/naso.module").then((m) => m.NasoModule),
  },
  {
    path: "preAdmission",
    component: SideBarSearchComponent,
    children: [
      {
        path: "admission",
        loadChildren: () =>
          import("./admission/admission.module").then((m) => m.AdmissionModule),
      },
    ],
  },

  {
    path: "payment",
    loadChildren: () =>
      import("./payment/payment.module").then((m) => m.PaymentModule),
  },

  {
    path: "admission",
    loadChildren: () =>
      import("./admission/admission.module").then((m) => m.AdmissionModule),
  },
  { path: "**", component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
