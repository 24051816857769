import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonImportsModule} from '../CommonImportsO/common-imports.module';

import { SmsUpdatesComponent } from './sms-updates/sms-updates.component';
import { SmsUpdatesSchoolListComponent } from './sms-updates-school-list/sms-updates-school-list.component';
import { PadhaiPayQueryListComponent } from './padhai-pay-query-list/padhai-pay-query-list.component';
import { PPAdminSyncAllComponent } from './ppadmin-sync-all/ppadmin-sync-all.component';
import { OnlineTransactionComponent } from './online-transaction/online-transaction.component';
import { SmAgentComponent } from './sm-agent/sm-agent.component';



@NgModule({
  declarations: [
    SmsUpdatesSchoolListComponent,
    SmsUpdatesComponent,
    PadhaiPayQueryListComponent,
    PPAdminSyncAllComponent,
    OnlineTransactionComponent,
    SmAgentComponent
  ],
  imports: [
    CommonModule,
    CommonImportsModule
  ]
})
export class PPAdminModule { }
