import { Component, OnInit } from "@angular/core";
import { SMSUpdatesService } from "../sms-updates/smsUpdates.service";
import { NotifierService } from "angular-notifier";
import { Router } from "@angular/router";
import { MerchantService } from "src/app/merchant-module/merchantService/merchant.service";
import { paymentModel } from "../modelClasses/paymentModule";

declare var $: any;

@Component({
  selector: "app-sms-updates-school-list",
  templateUrl: "./sms-updates-school-list.component.html",
  styleUrls: ["./sms-updates-school-list.component.scss"],
})
export class SmsUpdatesSchoolListComponent implements OnInit {
  constructor(
    public merchantService: MerchantService,
    private SMSService: SMSUpdatesService,
    private notify: NotifierService,
    public router: Router,
    public moduleObject: paymentModel
  ) {}

  schoolList = [];
  TotalSchool = 0;
  coloumns = [];
  SchoolName = "";
  varButton = ["Select Type"];
  moduleList = ["Admission Enquiry", "Admission Fee", "Registration Fee"];
  onlinePaymentList = [new paymentModel()];
  schoolId: any;
  schoolResult = [];
  fullFee: boolean = false;
  onlyPayment: boolean = false;

  ngOnInit() {
    let action_btn = "<a>Select Type</a>";
    let action_btn1 =
      '<i  class="fas fa-plus text-info add" title="Add Merchant"> Add</i>';
    let action_btn2 =
      '<i  class="fas fa-cogs text-info settings" title="Payment Settings"> Settings</i>';

    this.coloumns = [
      { data: "sourceId", defaultContent: "<i>Not Set</i>", title: "Source" },
      { data: "_id", defaultContent: "<i>Not Set</i>", title: "School Id" },
      { data: "name", defaultContent: "<i>Not Set</i>", title: "School Name" },
      {
        data: "smEmployeeId",
        defaultContent: "<i>Not Set</i>",
        title: "SM Employee-Id",
      },
      {
        data: "senderId",
        defaultContent: "<i>Not Set</i>",
        title: "Sender Id",
      },
      { data: "smsSend", defaultContent: "<i>Not Set</i>", title: "SMS Send" },
      {
        data: "smsBalance",
        defaultContent: "<i>Not Set</i>",
        title: "SMS Balance",
      },
      { data: "address", defaultContent: "<i>Not Set</i>", title: "Address" },
      {
        data: "phone",
        defaultContent: "<i>Not Set</i>",
        title: "Contact Number",
      },
      {
        data: "createdAt",
        defaultContent: "<i>Not Set</i>",
        title: "Account Created",
      },
      {
        data: "onlineFee",
        className: "center",
        defaultContent: action_btn,
      },
      {
        data: "ccAvenue",
        className: "center",
        defaultContent: action_btn,
      },
      {
        data: "rzpHdfc",
        className: "center",
        defaultContent: action_btn,
      },
      {
        data: "cashfree",
        className: "center",
        defaultContent: action_btn,
      },
      {
        data: "paytmMerchant",
        className: "center",
        defaultContent: action_btn,
      },
      {
        data: "greyQuest",
        className: "center",
        defaultContent: action_btn,
      },
      {
        data: "billdesk",
        className: "center",
        defaultContent: action_btn,
      },
      {
        data : "ezetap",
        className : "center",
        defaultContent : action_btn
      },
      {
        data : "eazyPay",
        className : "center",
        defaultContent : action_btn
      },
      {
        data : "kNet",
        className : "center",
        defaultContent : action_btn
      },
      {
        data: "smartPay",
        className: "center",
        defaultContent: action_btn,
      },
      {
        data: "enableAdmission",
        className: "center",
        defaultContent: action_btn,
      },
      {
        data: "merchantAcount",
        className: "center",
        defaultContent: action_btn1,
      },
      {
        data: null,
        className: "center",
        defaultContent: action_btn2,
      },
    ];
    this.getSchoolList();
  }

  getSchoolList() {
    this.SMSService.getSchoolName().subscribe((res) => {
      this.schoolList = res;
      this.schoolResult = res;
      console.log("schoolist", this.schoolList);

      this.populateTable(true);
    });
  }

  populateTable(active: boolean) {
    let self = this;
    if ($.fn.DataTable.isDataTable(".example")) {
      $(".example").DataTable().clear().destroy();
    }
    $(".showhideTable").css("display", "block");
    const example = $(".example").DataTable({
      data: this.schoolList,
      columns: this.coloumns,
      scrollX: true,
      dom: "Bfrtip",

      lengthMenu: [
        [10, 25, 50, -1],
        [10, 25, 50, "All"],
      ],
    });
    clickCount = 0;
    $(".example").on("click", "a.enable", function () {
      if (clickCount > 0) {
        return;
      }
      clickCount++;
      console.log("clicked............");
      var p = this.closest("tr");
      var data = $(".example").DataTable().row(p).index();
      self.enableOnlinePayment(self.schoolList[data]);
    });
    $(".example").on("click", "a.ccavenue", function () {
      if (clickCount > 0) {
        return;
      }
      clickCount++;
      console.log("clicked............");
      var p = this.closest("tr");
      var data = $(".example").DataTable().row(p).index();
      self.enableCcAvenue(self.schoolList[data]);
    });
    $(".example").on("click", "a.rzpHdfc", function () {
      if (clickCount > 0) {
        return;
      }
      clickCount++;
      console.log("clicked............");
      var p = this.closest("tr");
      var data = $(".example").DataTable().row(p).index();
      self.enableRzpHdfc(self.schoolList[data]);
    });

    $(".example").on("click", "a.cashfree", function () {
      if (clickCount > 0) {
        return;
      }
      clickCount++;
      console.log("clicked............");
      var p = this.closest("tr");
      var data = $(".example").DataTable().row(p).index();
      self.enableCashFree(self.schoolList[data]);
    });

    $(".example").on("click", "a.paytmMerchant", function () {
      if (clickCount > 0) {
        return;
      }
      clickCount++;
      console.log("clicked............");
      var p = this.closest("tr");
      var data = $(".example").DataTable().row(p).index();
      self.enablePaytm(self.schoolList[data]);
    });

    $(".example").on("click", "a.greyQuest", function () {
      if (clickCount > 0) {
        return;
      }
      clickCount++;
      console.log("clicked............");
      var p = this.closest("tr");
      var data = $(".example").DataTable().row(p).index();
      self.enableGreyQuest(self.schoolList[data]);
    });


    $(".example").on("click", "a.billdesk", function () {
      if (clickCount > 0) {
        return;
      }
      clickCount++;
      console.log("clicked............");
      var p = this.closest("tr");
      var data = $(".example").DataTable().row(p).index();
      self.enableBillDesk(self.schoolList[data]);
    });

    

    $(".example").on("click", "a.ezetap", function () {
      if (clickCount > 0) {
        return;
      }
      clickCount++;
      console.log("clicked............");
      var p = this.closest("tr");
      var data = $(".example").DataTable().row(p).index();
      self.enableEzetap(self.schoolList[data]);
    });

    
    $(".example").on("click", "a.eazyPay", function () {
      if (clickCount > 0) {
        return;
      }
      clickCount++;
      console.log("clicked............");
      var p = this.closest("tr");
      var data = $(".example").DataTable().row(p).index();
      self.enableEazyPay(self.schoolList[data]);
    });

    $(".example").on("click", "a.kNet", function () {
      if (clickCount > 0) {
        return;
      }
      clickCount++;
      console.log("clicked............");
      var p = this.closest("tr");
      var data = $(".example").DataTable().row(p).index();
      self.enablekNet(self.schoolList[data]);
    });

    $(".example").on("click", "a.smartPay", function () {
      if (clickCount > 0) {
        return;
      }
      clickCount++;
      console.log("clicked smartPay............");
      var p = this.closest("tr");
      var data = $(".example").DataTable().row(p).index();
      self.enableSmartPay(self.schoolList[data]);
    });

    $(".example").on("click", "a.admission", function () {
      if (clickCount > 0) {
        return;
      }
      clickCount++;
      console.log("clicked............");
      var p = this.closest("tr");
      var data = $(".example").DataTable().row(p).index();
      self.enableAdmission(self.schoolList[data]);
    });
    $(".example").on("click", "i.add", function () {
      if (clickCount > 0) {
        return;
      }
      clickCount++;
      console.log("clicked....add");
      var p = this.closest("tr");
      var data = $(".example").DataTable().row(p).index();
      self.addMerchant(self.schoolList[data]._id);
    });
    $(".example").on("click", "i.settings", function () {
      if (clickCount > 0) {
        return;
      }
      clickCount++;
      console.log("clicked....add");
      var p = this.closest("tr");
      var data = $(".example").DataTable().row(p).index();
      self.moduleDetails(self.schoolList[data]._id);
    });
  }

  enableOnlinePayment(data: any) {
    var obj = {};
    obj["_id"] = data._id;
    if (
      data.onlineFee ==
      '<a  type="button" class="text-info enable" title="Enable Online Payment"><font color="green"><b>Enable</b></font></a>'
    ) {
      obj["data"] = true;
    } else {
      obj["data"] = false;
    }
    this.SMSService.updateOnlinePayment(obj).subscribe(
      (res) => {
        if (res["status"]) {
          console.log("res", res);
          this.notify.notify("success", "Status Updated Successfully");
          this.getSchoolList();
        }
      },
      (error) => {
        this.notify.notify("error", "Oops! Network Error.");
      }
    );
  }

  enableAdmission(data: any) {
    console.log("data", data);
    var obj = {};
    obj["_id"] = data._id;
    if (
      data.enableAdmission ==
      '<a  type="button" class="text-info admission" title="Enable Admission Module"><font color="green"><b>Enable</b></font></a>'
    ) {
      obj["data"] = true;
    } else {
      obj["data"] = false;
    }
    this.SMSService.updateAdmission(obj).subscribe(
      (res) => {
        if (res["status"]) {
          console.log("updateAdmission", res);
          this.notify.notify("success", "Status Updated Successfully");
          this.getSchoolList();
        }
      },
      (error) => {
        this.notify.notify("error", "Oops! Network Error.");
      }
    );
  }

  enableCcAvenue(data: any) {
    console.log("data ccavwnue", data);
    var obj = {};
    obj["_id"] = data._id;
    if (
      data.ccAvenue ==
      '<a  type="button" class="text-info ccavenue" title="Enable ccAvenue Payment"><font color="green"><b>Enable</b></font></a>'
    ) {
      obj["ccAvenue"] = true;
    } else {
      obj["ccAvenue"] = false;
    }
    this.SMSService.updateOnlinePayment(obj).subscribe(
      (res) => {
        if (res["status"]) {
          console.log("ccAvenue", res);
          this.notify.notify("success", "Status Updated Successfully");
          this.getSchoolList();
        }
      },
      (error) => {
        this.notify.notify("error", "Oops! Network Error.");
      }
    );
  }

  enableRzpHdfc(data: any) {
    console.log("data ccavwnue", data);
    var obj = {};
    obj["_id"] = data._id;
    if (
      data.rzpHdfc ==
      '<a  type="button" class="text-info rzpHdfc" title="Enable rzpHdfc Payment"><font color="green"><b>Enable</b></font></a>'
    ) {
      obj["rzpHdfc"] = true;
    } else {
      obj["rzpHdfc"] = false;
    }
    this.SMSService.updateOnlinePayment(obj).subscribe(
      (res) => {
        if (res["status"]) {
          console.log("rzpHdfc", res);
          this.notify.notify("success", "Status Updated Successfully");
          this.getSchoolList();
        }
      },
      (error) => {
        this.notify.notify("error", "Oops! Network Error.");
      }
    );
  }

  enableCashFree(data: any) {
    console.log("data cashfree", data);
    var obj = {};
    obj["_id"] = data._id;
    if (
      data.cashfree ==
      '<a  type="button" class="text-info cashfree" title="Enable cashfree Payment"><font color="green"><b>Enable</b></font></a>'
    ) {
      obj["cashfree"] = true;
    } else {
      obj["cashfree"] = false;
    }
    console.log("res for cashfree" , obj);
    this.SMSService.updateOnlinePayment(obj).subscribe(
      (res) => {
        if (res["status"]) {
          console.log("cashfree", res);
          this.notify.notify("success", "Status Updated Successfully");
          this.getSchoolList();
        }
      },
      (error) => {
        this.notify.notify("error", "Oops! Network Error.");
      }
    );
  }

  enablePaytm(data :any){
    console.log("data paytmMerchant", data);
    var obj = {};
    obj["_id"] = data._id;
    if (
      data.paytmMerchant ==
      '<a  type="button" class="text-info paytmMerchant" title="Enable paytmMerchant Payment"><font color="green"><b>Enable</b></font></a>'
    ) {
      obj["paytmMerchant"] = true;
    } else {
      obj["paytmMerchant"] = false;
    }
    console.log("res for paytmMerchant" , obj);
    this.SMSService.updateOnlinePayment(obj).subscribe(
      (res) => {
        if (res["status"]) {
          console.log("paytmMerchant", res);
          this.notify.notify("success", "Status Updated Successfully");
          this.getSchoolList();
        }
      },
      (error) => {
        this.notify.notify("error", "Oops! Network Error.");
      }
    );
  }

  enableGreyQuest(data :any){
    console.log("data greyQuest", data);
    var obj = {};
    obj["_id"] = data._id;
    if (
      data.greyQuest ==
      '<a  type="button" class="text-info greyQuest" title="Enable greyQuest Payment"><font color="green"><b>Enable</b></font></a>'
    ) {
      obj["greyQuest"] = true;
    } else {
      obj["greyQuest"] = false;
    }
    console.log("res for greyQuest" , obj);
    this.SMSService.updateOnlinePayment(obj).subscribe(
      (res) => {
        if (res["status"]) {
          console.log("greyQuest", res);
          this.notify.notify("success", "Status Updated Successfully");
          this.getSchoolList();
        }
      },
      (error) => {
        this.notify.notify("error", "Oops! Network Error.");
      }
    );
  }


  enableBillDesk(data :any){
    console.log("data billdesk", data);
    var obj = {};
    obj["_id"] = data._id;
    if (
      data.billdesk ==
      '<a  type="button" class="text-info billdesk" title="Enable billdesk Payment"><font color="green"><b>Enable</b></font></a>'
    ) {
      obj["billdesk"] = true;
    } else {
      obj["billdesk"] = false;
    }
    console.log("res for billdesk" , obj);
    this.SMSService.updateOnlinePayment(obj).subscribe(
      (res) => {
        if (res["status"]) {
          console.log("billdesk", res);
          this.notify.notify("success", "Status Updated Successfully");
          this.getSchoolList();
        }
      },
      (error) => {
        this.notify.notify("error", "Oops! Network Error.");
      }
    );
  }

  enableEzetap(data :any){
    console.log("data ezetap", data);
    var obj = {};
    obj["_id"] = data._id;
    if (
      data.ezetap ==
      '<a  type="button" class="text-info ezetap" title="Enable ezetap Payment"><font color="green"><b>Enable</b></font></a>'
    ) {
      obj["ezetap"] = true;
    } else {
      obj["ezetap"] = false;
    }
    console.log("res for ezetap" , obj);
    this.SMSService.updateOnlinePayment(obj).subscribe(
      (res) => {
        if (res["status"]) {
          console.log("ezetap", res);
          this.notify.notify("success", "Status Updated Successfully");
          this.getSchoolList();
        }
      },
      (error) => {
        this.notify.notify("error", "Oops! Network Error.");
      }
    );
  }

  enableEazyPay(data :any){
    console.log("data eazyPay", data);
    var obj = {};
    obj["_id"] = data._id;
    if (
      data.eazyPay ==
      '<a  type="button" class="text-info eazyPay" title="Enable eazyPay Payment"><font color="green"><b>Enable</b></font></a>'
    ) {
      obj["eazyPay"] = true;
    } else {
      obj["eazyPay"] = false;
    }
    console.log("res for eazyPay" , obj);
    this.SMSService.updateOnlinePayment(obj).subscribe(
      (res) => {
        if (res["status"]) {
          console.log("eazyPay", res);
          this.notify.notify("success", "Status Updated Successfully");
          this.getSchoolList();
        }
      },
      (error) => {
        this.notify.notify("error", "Oops! Network Error.");
      }
    );
  }

  enablekNet(data :any){
    console.log("data kNet", data);
    var obj = {};
    obj["_id"] = data._id;
    if (
      data.kNet ==
      '<a  type="button" class="text-info kNet" title="Enable kNet Payment"><font color="green"><b>Enable</b></font></a>'
    ) {
      obj["kNet"] = true;
    } else {
      obj["kNet"] = false;
    }
    console.log("res for kNet" , obj);
    this.SMSService.updateOnlinePayment(obj).subscribe(
      (res) => {
        if (res["status"]) {
          console.log("kNet", res);
          this.notify.notify("success", "Status Updated Successfully");
          this.getSchoolList();
        }
      },
      (error) => {
        this.notify.notify("error", "Oops! Network Error.");
      }
    );
  }

  enableSmartPay(data:any){
    console.log("data smartPay", data);
    var obj = {};
    obj["_id"] = data._id;
    if (
      data.smartPay ==
      '<a  type="button" class="text-info smartPay" title="Enable smartPay Payment"><font color="green"><b>Enable</b></font></a>'
    ) {
      obj["smartPay"] = true;
    } else {
      obj["smartPay"] = false;
    }
    console.log("res for smartPay" , obj);
    this.SMSService.updateOnlinePayment(obj).subscribe(
      (res) => {
        if (res["status"]) {
          console.log("smartPay", res);
          this.notify.notify("success", "Status Updated Successfully");
          this.getSchoolList();
        }
      },
      (error) => {
        this.notify.notify("error", "Oops! Network Error.");
      }
    );
  }


  moduleDetails(id: any) {
    this.schoolId = id;
    var arr = this.schoolResult;

    for (var i = 0; i < arr.length; i++) {
      if (arr[i]._id == id && arr[i].paymentModule.length > 0) {
        this.onlinePaymentList = arr[i].paymentModule;
        this.fullFee = arr[i].fullFee;
        this.onlyPayment = arr[i].onlyPayment;
        break;
      } else {
        this.fullFee = false;
        this.onlyPayment = false;
        this.onlinePaymentList = [new paymentModel()];
      }
    }
    console.log("onlinePaymentList", this.onlinePaymentList);

    $("#paymentModel").modal("show");
  }

  updateModulePaymentDetails() {
    console.log(
      "updateModulePaymentDetails",
      this.schoolId,
      this.onlinePaymentList
    );

    var obj = {};

    obj["schoolId"] = this.schoolId;
    obj["paymentModule"] = this.onlinePaymentList;
    obj["fullFee"] = this.fullFee;
    obj["onlyPayment"] = this.onlyPayment;

    this.SMSService.updateOnlinePaymentModule(obj).subscribe((res) => {
      console.log("res", res);
      this.onlinePaymentList = [new paymentModel()];
      this.notify.notify("info", "Successful Updated");
      $("#paymentModel").modal("hide");
      this.getSchoolList();
    });
  }
  addMerchant(id) {
    this.merchantService.merchantProfileId = id;
    this.router.navigate(["/dashboard/merchant/merchantCreate"]);
  }

  SMSUpdates() {
    this.router.navigate(["/dashboard/smsUpdates"]);
  }

  addNewRow() {
    this.onlinePaymentList.push(new paymentModel());
  }
  onRemoveRow(index) {
    if (index != 0) {
      let newArr = this.onlinePaymentList; // Imp. to refreshing
      newArr.splice(index, 1);
      this.onlinePaymentList = newArr;
    }
  }
}
let clickCount = 0 ;