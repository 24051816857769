import { Component, OnInit } from '@angular/core';
import { ppAdminSyncAll } from './ppadmin-sync-all.service';
import { OneStorage } from '../../GlobalDataStorage/oneStorage.service';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-ppadmin-sync-all',
  templateUrl: './ppadmin-sync-all.component.html',
  styleUrls: ['./ppadmin-sync-all.component.scss']
})
export class PPAdminSyncAllComponent implements OnInit {

  constructor(public PPASyncAll:ppAdminSyncAll, private globals: OneStorage, public notifier: NotifierService) { }

  syncAll: boolean = false;
  schoolId: string;
  schoolId1 : string;
  offlineSchoolId: string;


  ngOnInit() {
  }

  onSyncAll(newSchool, message, allSchool, feeAccess, admissionAccess){

    let that=this;

    //if(that.syncAll !== false){
      this.globals.confirmDialogService.confirmThis(message, 'Are you sure to sync ?', function () { 
        that.PPASyncAll.updateSyncAll(newSchool, allSchool, feeAccess, admissionAccess).subscribe(
          res => {
            console.log(res);
            if(res.status) {
              that.notifier.notify('success', 'Data is being synced.');
            }
      });
      }, function () {  
        that.notifier.notify('error', 'sync cancelled.');
      });
    //}

}

syncUser(allUser){

  let that=this;

  //if(that.syncAll !== false){
    this.globals.confirmDialogService.confirmThis("Sync All User For entered School Id", 'Are you sure to sync ?', function () { 
      that.PPASyncAll.syncUsers(that.schoolId, allUser).subscribe(
        res => {
          console.log(res);
          if(res.status) {
            that.notifier.notify('success', 'Users are being synced.');
          }
    });
    }, function () {  
      that.notifier.notify('error', 'sync cancelled.');
    });
  //}

}

removeUsers(){
  let that=this;
  //if(that.syncAll !== false){
  this.PPASyncAll.getSchool(this.schoolId1).subscribe(
    res => {
      console.log(res);
      if(res) {
        this.globals.confirmDialogService.confirmThis(`On clicking Yes, all the users of ${res.name} will be deleted?`, 'Delete Users', function () { 
          that.PPASyncAll.removeUsers(that.schoolId1).subscribe(
            res => {
              console.log(res);
              if(res.status) {
                that.notifier.notify('success', res.message);
              } else {
                that.notifier.notify('error', res.message);
              }
        });
        }, function () {  
          that.notifier.notify('error', 'sync cancelled.');
        });
      } else {
        that.notifier.notify('error', res.message);
      }
});
}

syncClasses(){
  let that=this;
  //if(that.syncAll !== false){
    this.globals.confirmDialogService.confirmThis("Sync Classes For entered School Id", 'Are you sure to sync ?', function () { 
      that.PPASyncAll.syncClasses(that.schoolId).subscribe(
        res => {
          console.log(res);
          if(res.status) {
            that.notifier.notify('success', res.message);
          } else {
            that.notifier.notify('error', res.message);
          }
    });
    }, function () {  
      that.notifier.notify('error', 'sync cancelled.');
    });
  //}

}

updateOfflineFlag(activate) {
  let that=this;
  var msg = ""
  if(activate) {
    msg = "Activate Offline For entered School Id"
  } else {
    msg = "Deactivate Offline For entered School Id"
  }
  //if(that.syncAll !== false){
    this.globals.confirmDialogService.confirmThis(msg, 'Are you sure to update offline status ?', function () { 
      that.PPASyncAll.updateOfflineFlag(that.offlineSchoolId, activate).subscribe(
        res => {
          console.log(res);
          if(res.status) {
            that.notifier.notify('success', 'Offline status updated successfully');
          }
    });
    }, function () {  
      that.notifier.notify('error', 'Error occurred.');
    });
  //}
}

}
