import { SchoolListForAgentComponent } from "src/app/admission/school-list-for-agent/school-list-for-agent.component";

export class BasicSchoolDetail {
    id: string;
    schoolName: string;
    sourceId: string;
    onlineFee: boolean;
    ccAvenue: boolean = false;
    rzpHdfc: boolean = false;
    cashfree: boolean = false;
    paytmMerchant : boolean = false;
    greyQuest : boolean = false;
    billdesk: boolean = false;
    smartPay: boolean = false;
    ezetap : boolean = false;
    eazyPay : boolean = false;
    kNet : boolean = false;
    skipClassesOnlinepayement =[];
    showFeehistory:boolean=false;
    smEmployeeId: string;
    skipClassesForOnlineTransport = [];;
    constructor(schoolDetail: any = undefined) {
        if (schoolDetail) {
            this.id = schoolDetail._id;
            this.schoolName = schoolDetail.name;
            this.onlineFee = schoolDetail.onlineFee;
            this.ccAvenue = schoolDetail.ccAvenue;
            this.rzpHdfc = schoolDetail.rzpHdfc;
            this.cashfree = schoolDetail.cashfree;
            this.paytmMerchant = schoolDetail.paytmMerchant;
            this.greyQuest = schoolDetail.greyQuest;
            this.billdesk = schoolDetail.billdesk;
            this.smartPay = schoolDetail.smartPay;
            this.ezetap = schoolDetail.ezetap;
            this.eazyPay = schoolDetail.eazyPay;
            this.kNet = schoolDetail.kNet;
            this.smEmployeeId = schoolDetail.smEmployeeId;
            if(typeof schoolDetail.skipClassesForOnline !=='undefined')
            {
this.skipClassesOnlinepayement=schoolDetail.skipClassesForOnline;
            }
            if(typeof schoolDetail.skipClassesForOnlineTransport !=='undefined')
                {
    this.skipClassesForOnlineTransport=schoolDetail.skipClassesForOnlineTransport;
                }
            
            if(typeof schoolDetail.showFeeHistoryAndFeeSummery !=='undefined')
            {
                this.showFeehistory=schoolDetail.showFeeHistoryAndFeeSummery;
            }
        }
    }
}