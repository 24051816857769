import { Injectable } from '@angular/core';
import {Router} from '@angular/router'
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {AllApisService} from '../../all-apis.service';

@Injectable({
  providedIn: 'root'
})
export class SMSUpdatesService {

  // category="";
  errorData: {};
  constructor(private _http: HttpClient ,private router:Router,private allApis:AllApisService) 
  { }

  senderIdUrl = this.allApis.baseurl + '/school/senderId';
  addSMSUrl = this.allApis.baseurl + '/school/addSMS';
  getPaymentDetails = this.allApis.baseurl + '/pay/paymentList';
  schoolListForSMS = this.allApis.baseurl+'/school/schoolListForSMS';

  schoolOnlineFeeUrl = this.allApis.baseurl+'/school/schoolOnlineFee';
  schoolAdmissionUrl = this.allApis.baseurl+'/school/schoolAdmission';
  schoolOnlineModuleUrl = this.allApis.baseurl+'/school/schoolOnlineModule';

  updateOnlinePaymentUrl = this.allApis.baseurl+'/pay/updateStatus';

  getAgentNameUrl = this.allApis.baseurl + '/smAgent';


  getSchoolName() {
    return this._http.get<any>(this.schoolListForSMS,this.allApis.httpOptions).pipe(catchError(this.handleError));
  }
  getAgentName() {
    return this._http.get<any>(this.getAgentNameUrl,this.allApis.httpOptions).pipe(catchError(this.handleError));
  }
  postAgentName(obj) {
    return this._http.post<any>(this.getAgentNameUrl,obj,this.allApis.httpOptions).pipe(catchError(this.handleError));
  }
  updateSchoolDetails(schoolReg:any){
    return this._http.patch<any>(this.senderIdUrl,schoolReg,this.allApis.httpOptions).pipe(catchError(this.handleError));
  }
  updateAddSmsSchoolDetails(schoolReg:any){
    return this._http.patch<any>(this.addSMSUrl,schoolReg,this.allApis.httpOptions).pipe(catchError(this.handleError));
  }

  updateOnlinePayment(obj){
    return this._http.patch<any>(this.schoolOnlineFeeUrl,obj,this.allApis.httpOptions).pipe(catchError(this.handleError));
  }
  updateAdmission(obj){
    return this._http.patch<any>(this.schoolAdmissionUrl,obj,this.allApis.httpOptions).pipe(catchError(this.handleError));
  }
  updateOnlinePaymentModule(obj){
    return this._http.patch<any>(this.schoolOnlineModuleUrl,obj,this.allApis.httpOptions).pipe(catchError(this.handleError));
  }
  updateOnlinePaymentStatus(obj){
    return this._http.post<any>(this.updateOnlinePaymentUrl,obj);
  }
  getPaymentList(startDate: string, endDate: string) {

    let params = new HttpParams();

    params = params.append('startDate', startDate);
    params = params.append('endDate', endDate);

    this.allApis.httpOptions.params = params;

    return this._http.get<any>(this.getPaymentDetails, this.allApis.httpOptions).pipe(catchError(this.handleError));
  }
 

//  error handling
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {

      // A client-side or network error occurred. Handle it accordingly.

      console.error('An error occurred:', error.error.message);
    } else {

      // The backend returned an unsuccessful response code.

      // The response body may contain clues as to what went wrong,

      console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
    }

    // return an observable with a user-facing error message

    this.errorData = {
      errorTitle: 'Oops! Request for document failed',
      errorDesc: 'Something bad happened. Please try again later.'
    };
    return throwError(this.errorData);
  }

}
