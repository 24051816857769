import { Injectable, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { FeeCollection } from '../model/feeCollectionModel';
import { FeeCategory } from '../model/FeeCategoryModel';
import { FeePayement } from '../model/feePayement';
import { Result } from '../model/resultModel';
import { AllApisService } from '../../../all-apis.service';

import { Observable, timer, throwError } from 'rxjs';
import { retryWhen, scan, delayWhen, catchError } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})

export class FeeCollectionService {
  errorData: {};
  obj;
  collectionDetailsArray: FeeCollection[];



  private studnet = this.allApisService.baseurl + '/user/studentList';
  // private apiUrl = this.allApisService.baseurl+'/feeCollection/getFeeDetails';
  private apiUrl1 = this.allApisService.baseurl + '/feeCollection/getFeeDetails/misc';
  private apiUrl2 = this.allApisService.baseurl + '/feeCollection/getFeeDetails/Transport';
  private saveapi = this.allApisService.baseurl + '/transactions';//studentFeeHistory
  private feehistory = this.allApisService.baseurl + '/transactions/studentFeeHistory';
  private feehistoryTransport = this.allApisService.baseurl + '/transactions/studentFeeHistory/Transport';
  private feeCancel = this.allApisService.baseurl + '/transactions/cancel';
  private chequeDuplicateCheck = this.allApisService.baseurl + '/transactions/checkChequeDetailsDelicacy';
  getAllSections = this.allApisService.getSection;
  getAllStandards = this.allApisService.getClass;
  private feehistorytr = this.allApisService.baseurl + '/transactions/studentFeeHistory/transaction';

  admitCardURL = this.allApisService.baseurl + '/transactions/getAdmitCard';


  private gefeerecieptApi = this.allApisService.baseurl + '/transactions/getDuplicateFeeReciept';

  private gefeerecieptApi1 = this.allApisService.baseurl + '/transactions/getDuplicateFeeReciept/Transport';

  constructor(private http: HttpClient, public allApisService: AllApisService) {

    console.log("helllo itialiszinf fee collections service");
  }
  //

  public getupdatedToken(schoolid) {
    let params = new HttpParams();

    params = params.append('schoolId', schoolid);
    this.allApisService.httpOptions.params = params;

    console.log("calllling for new token ");

    return this.http.get<any>(this.allApisService.updateToken, this.allApisService.httpOptions);

  }
  public chequeDuplicateCheckGet(chequeNo, bankName) {
    // console.log(id);chequeNo
    // var link =this.gefeerecieptApi+"?id="+id;

    let params = new HttpParams();
    params = params.append('chequeNo', chequeNo);
    params = params.append('bankName', bankName);
    this.allApisService.httpOptions.params = params;

    return this.http.get<any>(this.chequeDuplicateCheck, this.allApisService.httpOptions);
  }


  public getUserSchoolNames() {
    return this.http.get<any>(this.allApisService.schoolNameListUrl, this.allApisService.httpOptions)
      .pipe(retryWhen(err => err.pipe(scan(retryCount => {
        if (retryCount > 3) { throw (err); } else {
          retryCount++; return retryCount;
        }
      }, 0), delayWhen(() => timer(1000))))).pipe(catchError(this.handleError));
  }
  public getitrreport(studentId, std, section, fatherName, studentName, session) {
    // console.log(id);
    // var link =this.gefeerecieptApi+"?id="+id;

    let params = new HttpParams();

    params = params.append('std', std);
    params = params.append('session', session);


    params = params.append('section', section);

    params = params.append('studentId', studentId);
    params = params.append('fatherName', fatherName);
    params = params.append('studentName', studentName);

    this.allApisService.httpOptions.params = params;

    return this.http.get<any>(this.allApisService.itrReport, this.allApisService.httpOptions);
  }
  public generateFeeRecipt(studentId, std, section, fatherName, studentName, session,
    bankName, accountNo, ifscCode, institutionId, payable, month, feeCategory, admissionNo, contactNo, feeArray, cummelative) {
    let params = new HttpParams();
    params = params.append('std', std);
    params = params.append('session', session);
    params = params.append('section', section);
    params = params.append('studentId', studentId);
    params = params.append('fatherName', fatherName);
    params = params.append('studentName', studentName);

    params = params.append('bankName', bankName);
    params = params.append('accountNo', accountNo);
    params = params.append('ifscCode', ifscCode);
    params = params.append('institutionId', institutionId);

    params = params.append('payable', payable);
    params = params.append('month', month);
    params = params.append('admissionNo', admissionNo);
    params = params.append('feeCategory', feeCategory);
    params = params.append('contactNo', contactNo);
    params = params.append('cummelative', cummelative);
    params = params.append('feeArray', feeArray);
    //contactNo
    this.allApisService.httpOptions.params = params;
    return this.http.patch<any>(this.allApisService.itrReportreciept, { feeArray: feeArray }, this.allApisService.httpOptions);
  }

  public getitrreports(feeCategory, amount, std, section, fatherName, mothername, studentName, fromto, date, session, studnetId) {
    let params = new HttpParams();
    params = params.append('date', date)
    params = params.append('std', std);
    params = params.append('amount', amount);
    params = params.append('feeCategory', feeCategory);
    params = params.append('section', section);
    params = params.append('mothername', mothername);
    params = params.append('fatherName', fatherName);
    params = params.append('studentName', studentName);
    params = params.append('fromto', fromto);
    params = params.append('session', session);
    params = params.append('studnetId', studnetId);
    this.allApisService.httpOptions.params = params;

    return this.http.get<any>(this.allApisService.itrReports, this.allApisService.httpOptions);
  }
  public getFeeStructure(studentId, std, section, fatherName, studentName, session) {

    let params = new HttpParams();
    params = params.append('std', std);
    params = params.append('section', section);
    params = params.append('studentId', studentId);
    params = params.append('fatherName', fatherName);
    params = params.append('studentName', studentName);
    params = params.append('session', session);
    this.allApisService.httpOptions.params = params;

    return this.http.get<any>(this.allApisService.feeStructure, this.allApisService.httpOptions);
  }

  public getDuplicateFeeRecieptFromId(id: any) {
    console.log(id);
    var link = this.gefeerecieptApi + "?id=" + id;

    return this.http.get<any>(link, this.allApisService.httpOptions);
  }


  public getDuplicateFeeRecieptFromId1(id: any) {
    console.log(id);
    var link = this.gefeerecieptApi1 + "?id=" + id;

    return this.http.get<any>(link, this.allApisService.httpOptions);
  }

  cancelFee(value) {
    console.log('helllo got respose');
    return this.http.patch(this.feeCancel, value, this.allApisService.httpOptions);
  }
  public addTransaction(payement, chequeFlow2 = false, cashFlow) {
    let params = new HttpParams();
    params = params.append('chequeFlow2', chequeFlow2.toString());
    params = params.append('cashFlow', cashFlow.toString());
    //params = params.append('data', data.toString());
    this.allApisService.httpOptions.params = params;
    return this.http.post(this.saveapi, payement, this.allApisService.httpOptions);
  }

  getStandard() {
    return this.http.get<any>(this.getAllStandards, this.allApisService.httpOptions);
  }

  getFeeHistory(id, studentSourceId: any = undefined, session: any = undefined, role = "") {
    let params = new HttpParams();
    if (studentSourceId) {
      params = params.append('studentSourceId', studentSourceId);
      params = params.append('session', session);
    }
    params = params.append('role', role);
    this.allApisService.httpOptions.params = params;
    return this.http.get(this.feehistory + '?id=' + id, this.allApisService.httpOptions);
  }



  getFeeHistoryTransport(id, studentSourceId: any = undefined, session: any = undefined, role = "") {
    let params = new HttpParams();
    if (studentSourceId) {
      params = params.append('studentSourceId', studentSourceId);
      params = params.append('session', session);
    }
    params = params.append('role', role);
    this.allApisService.httpOptions.params = params;
    return this.http.get(this.feehistoryTransport + '?id=' + id, this.allApisService.httpOptions);
  }

  getFeeHistorytr(id, studentSourceId: any = undefined, session: any = undefined, role = "") {
    if (studentSourceId) {
      let params = new HttpParams();
      params = params.append('studentSourceId', studentSourceId);
      params = params.append('session', session);
      params = params.append('role', role);

      this.allApisService.httpOptions.params = params;
    }

    return this.http.get(this.feehistorytr + '?id=' + id, this.allApisService.httpOptions);
  }

  getdata(std: string, section: string, studentId: string, studentSourceId: any = undefined, session: any = undefined) {
    console.log('helllo got respose');
    let params = new HttpParams();
    if (std) {
      params = params.append('class', std);
    }
    if (section) {
      params = params.append('section', section);
    }
    if (studentId) {
      params = params.append('studentId', studentId);
    }
    if (studentSourceId) {
      params = params.append('studentSourceId', studentSourceId);
    }
    if (session) {
      params = params.append("studentSession", session);
    }
    this.allApisService.httpOptions.params = params;
    return this.http.get(this.apiUrl1, this.allApisService.httpOptions);
  }
  getdata1(std: string, section: string, studentId
    : string, studentSourceId: any = undefined, session: any = undefined, feeType: any = undefined) {
    console.log('helllo got respose');
    let params = new HttpParams();
    if (std) {
      params = params.append('class', std);
    }
    if (section) {
      params = params.append('section', section);
    }
    if (studentId) {
      params = params.append('studentId', studentId);
    }
    if (studentSourceId) {
      params = params.append('studentSourceId', studentSourceId);
    }
    if (session) {
      params = params.append('studentSession', session);
    }
    if (feeType) {
      params = params.append("feeType", feeType);
    }
    this.allApisService.httpOptions.params = params;
    return this.http.get(this.apiUrl1, this.allApisService.httpOptions);
  }

  getdatatransport(std: string, section: string, studentId
    : string, studentSourceId: any = undefined, session: any = undefined) {
    console.log('helllo got respose');
    let params = new HttpParams();
    if (std) {
      params = params.append('class', std);
    }
    if (section) {
      params = params.append('section', section);
    }
    if (studentId) {
      params = params.append('studentId', studentId);
    }
    if (studentSourceId) {
      params = params.append('studentSourceId', studentSourceId);
    }
    if (session) {
      params = params.append('studentSession', session);
    }
    this.allApisService.httpOptions.params = params;
    return this.http.get(this.apiUrl2, this.allApisService.httpOptions);
  }

  onNavigate(link: String) {
    //this.router.navigateByUrl("https://www.google.com");
    window.location.href = "file:///E:/nenodefeeproject/fee-nodejs/feeReceipt/5daec34f3f43aa46b032d365/undefined/student2vv.pdf";
  }

  getStudentListData(std: any, section: any, searchType: any, searchTerm: any, session) {
    let params = new HttpParams();
    params = params.append("section", section);
    params = params.append("class", std);
    params = params.append("searchType", searchType);
    params = params.append("searchTerm", searchTerm);
    params = params.append("session", session);

    params = params.append("studentSession", session);

    console.log("class", std, searchType, searchTerm);
    this.allApisService.httpOptions.params = params;
    console.log("option for getti data" + this.studnet, this.allApisService.httpOptions.params);
    return this.http.get(this.studnet, this.allApisService.httpOptions);
  }
  admitCard(id: string) {

    let params = new HttpParams();

    params = params.append('id', id);

    this.allApisService.httpOptions.params = params;

    return this.http.get<any>(this.admitCardURL, this.allApisService.httpOptions);
  }
  getOthersInfo() {
    return this.http.get<any>(this.allApisService.getAdmitCardOnCollectFeeUrl, this.allApisService.httpOptions)
  }

  getDataForMonth(months: String[]) {
    console.log(months);
    this.obj.forEach(element => {
      console.log(element);

    });

  };

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {

      // A client-side or network error occurred. Handle it accordingly.

      console.error('An error occurred:', error.error.message);
    } else {

      // The backend returned an unsuccessful response code.

      // The response body may contain clues as to what went wrong,

      console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
    }

    // return an observable with a user-facing error message

    this.errorData = {
      errorTitle: 'Oops! Request for document failed',
      errorDesc: 'Something bad happened. Please try again later.'
    };
    return throwError(this.errorData);
  }
}

